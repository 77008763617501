import { defineStore } from "pinia";
import { now } from "@vueuse/core";

type State = {
  name: string | undefined;
  description: string | undefined;
  amount: number;
  hashTags: string[];
  expirationDate: Date | undefined;
  visibility: "public" | "private";
  challengedUserEmailsString: string | undefined;
  challengedUsername: string | undefined;
  timestamp: number;
  acceptCredit: boolean;
  challengeMultipleFriends: boolean;
};

// https://pinia.vuejs.org/core-concepts/#Setup-Stores
export const useCreateChallengeStore = defineStore(
  "createChallengeStore",
  () => {
    const { verifyingUser } = toRefs(useAuthStore());

    const mustNotContainCreatorEmail = (value: string) => {
      if (!value || !verifyingUser.value || !verifyingUser.value.email)
        return true;

      value = value.toLowerCase();

      return !value.includes(verifyingUser.value.email.toLowerCase());
    };

    const createDefaults = (): State => ({
      name: "",
      description: "",
      amount: 5,
      hashTags: [],
      expirationDate: new Date(Date.now() + 1209600000),
      visibility: "public",
      challengedUserEmailsString: undefined,
      challengedUsername: undefined,
      timestamp: Date.now(),
      acceptCredit: false,
      challengeMultipleFriends: false,
    });

    const state = reactive<State>(createDefaults());

    const flush = () => Object.assign(state, createDefaults());

    const isStep1Valid = () => {
      if (!state.name || !state.amount) {
        return false;
      }

      if (state.amount < 0.1) {
        return false;
      }

      if (state.name.length < 3) {
        return false;
      }

      return true;
    };

    const isStep2Valid = () => {
      if (!state.description) {
        return false;
      }

      if (state.description.length < 3) {
        return false;
      }

      if (state.description.length > 300) {
        return false;
      }

      if (state.hashTags.length < 1) {
        return false;
      }

      if (!state.expirationDate) {
        return false;
      }

      if (state.expirationDate < now()) {
        return false;
      }

      if (!state.acceptCredit) {
        return false;
      }

      if (state.challengeMultipleFriends && !state.challengedUsername) {
        if (
          !state.challengedUserEmailsString ||
          getEmailsFromText(state.challengedUserEmailsString).length < 1
        ) {
          return false;
        }

        if (!mustNotContainCreatorEmail(state.challengedUserEmailsString)) {
          return false;
        }
      }

      if (state.challengeMultipleFriends && state.challengedUsername) {
        return false;
      }

      return true;
    };

    return {
      ...toRefs(state),

      // Methods
      isStep1Valid,
      isStep2Valid,
      flush,
    };
  },
  {
    persist: true,
  }
);

<template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-col gap-4">
      <SendInput
        v-model="v.username.$model"
        type="input"
        :placeholder="$t('placeholder_username')"
        size="lg"
        @keyup.enter.stop="!v.$invalid && loginWithAccount()"
      ></SendInput>
      <SendInput
        v-model="v.password.$model"
        :placeholder="$t('placeholder_password')"
        type="input"
        sub-type="password"
        size="lg"
        @keyup.enter.stop="!v.$invalid && loginWithAccount()"
      ></SendInput>
      <div class="border-send-grey-20 border-t" />

      <div class="flex items-center justify-between gap-4">
        <NuxtLink
          class="cursor-pointer bg-action-gradient bg-clip-text font-medium [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]"
          @click="goToForgotPassword"
          >{{ $t("forgot_password") }}?
        </NuxtLink>

        <SendBaseButton
          variant="primary"
          size="sm"
          :label="$t('log_in')"
          :disabled="v.$invalid || loginUsingAccount.loading"
          @click="loginWithAccount"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from "~/stores/auth";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
import SendInput from "~/components/partials/SendInput.vue";
import SendBaseButton from "~/components/partials/SendBaseButton.vue";

const form = reactive({
  password: "",
  username: "",
});

const toast = useToast();
const router = useRouter();

const emit = defineEmits(["login", "close"]);

const { loginUsingAccount } = useAuthStore();

const v = useVuelidate(
  {
    username: { required },
    password: { required },
  },
  form
);

const loginWithAccount = async () => {
  if (v.$invalid) return;

  if (loginUsingAccount.loading) return;

  await loginUsingAccount.mutate({
    username: form.username,
    password: form.password,
  });
};

const goToForgotPassword = async () => {
  await router.push("/login/recover/request");
  emit("close");
};

loginUsingAccount.onDone((result) => {
  if (result.data?.login.accessToken) emit("login", result);
});

loginUsingAccount.onError((error) => {
  toast.error(error.message);
});
</script>

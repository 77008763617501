<template>
  <component :is="as" v-if="hasSlotContent" :class="computedClasses">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { Comment, computed, Text, useSlots } from "vue";

const slots = useSlots();

// Check if the slot has content
const hasSlotContent = computed(
  () =>
    slots.default &&
    slots.default().some((vnode) => {
      // Check if the VNode is not a Comment or Text node, or it has meaningful content
      return (
        (vnode.type !== Comment && vnode.type !== Text) ||
        (vnode.children && vnode.children.toString().trim() !== "")
      );
    })
);

//eslint-disable-next-line
type ValidTag = keyof HTMLElementTagNameMap;

type Sizes = "xxl" | "xl" | "lg" | "md" | "sm" | "xs" | "xxs";
type Variant = "heading" | "text";
type Weight = "normal" | "medium" | "semibold" | "bold";

interface Props {
  as?: ValidTag;
  variant?: Variant;
  size?: Sizes;
  color?: string;
  weight?: Weight;
}

const props = withDefaults(defineProps<Props>(), {
  as: "p",
  variant: "text",
  size: "md",
  color: "text-send-almost-black",
  weight: "normal",
});

const sizes = {
  heading: {
    xxl: "send-title text-[56px] font-semibold leading-[100%]",
    xl: "send-default text-3xl md:text-[40px] font-medium leading-[100%]",
    lg: "send-default text-4xl font-bold leading-[100%]",
    md: "send-default text-[32px] font-medium leading-[110%]",
    sm: "send-default text-2xl font-medium leading-[120%]",
    xs: "send-default text-xl font-bold leading-[110%]",
    xxs: "send-default text-md font-bold leading-[110%]",
  },
  text: {
    xxl: "send-default text-2xl leading-[160%]",
    xl: "send-default text-xl leading-[160%]",
    lg: "[leading-trim:both] [text-edge:cap] send-default text-lg leading-[160%]",
    md: "send-default text-base leading-[160%]",
    sm: "send-default text-sm leading-[140%]",
    xs: "send-default text-xs leading-[140%]",
    xxs: "send-default text-xs leading-[140%]",
  },
};

const computedClasses = computed(() => {
  const fontFamily = computed(() => {
    if (props.as)
      return ["h1", "h2", "h3", "h4", "h5", "h6"].includes(props.as)
        ? "font-send-title"
        : "";
    return "";
  });
  let fontWeight = "font-normal";

  if (props.weight === "medium") {
    fontWeight = "font-medium";
  } else if (props.weight === "semibold") {
    fontWeight = "font-semibold";
  } else if (props.weight === "bold") {
    fontWeight = "font-bold";
  }

  return `${sizes[props.variant][props.size]} ${props.color} ${fontWeight} ${fontFamily.value}`.trim();
});
</script>

<template>
  <div class="flex flex-col gap-4 lg:h-full lg:justify-between">
    <div>
      <NuxtLink class="w-fit" to="/challenge/create">
        <SendBaseButton
          :label="$t('sidebar_start_a_challenge')"
          icon-name="lightning_bolt"
          icon-position="start"
          size="xs"
          variant="action-gradient"
        ></SendBaseButton>
      </NuxtLink>

      <ul class="text-grey mt-10 flex flex-col space-y-4">
        <li>
          <NuxtLink
            :to="`/profile/${username}/running-challenges`"
            active-class="active"
            class="block max-h-max w-fit"
          >
            <SendBaseButton
              :label="$t('sidebar_my_profile')"
              class="!gap-1 border-none bg-send-almost-black hover:!bg-black hover:!text-white"
              icon-name="Profile"
              icon-position="start"
              size="xs"
              variant="primary"
            />
          </NuxtLink>
        </li>
        <li>
          <NuxtLink
            active-class="active"
            class="block max-h-max w-fit"
            to="/challenge-dashboard"
          >
            <SendBaseButton
              :label="$t('sidebar_my_dashboard')"
              class="!gap-1 border-none bg-send-almost-black hover:!bg-black hover:!text-white"
              icon-name="Dashboard"
              icon-position="start"
              size="xs"
              variant="primary"
            />
          </NuxtLink>
        </li>
        <li>
          <NuxtLink
            active-class="active"
            class="block max-h-max w-fit"
            to="/wallet"
          >
            <SendBaseButton
              :label="$t('wallet')"
              class="!gap-1 border-none bg-send-almost-black hover:!bg-black hover:!text-white"
              icon-name="Wallet"
              icon-position="start"
              size="xs"
              variant="primary"
            />
          </NuxtLink>
        </li>
      </ul>
      <ul class="text-grey mt-6 leading-[3rem]">
        <li>
          <NuxtLink
            :to="`/profile/${username}/running-challenges`"
            active-class="active"
            class="flex items-center gap-4 rounded-xl bg-send-almost-black p-2 px-4"
          >
            <FwbAvatar
              :img="avatar ?? ''"
              :initials="displayName?.[0] ?? ''"
              status="online"
              status-position="bottom-right"
            />
            <SendTypography
              v-if="displayName"
              :as="displayName.length > 10 ? 'h6' : 'h5'"
              color="text-white"
              truncate
              variant="text"
            >
              {{ displayName }}
            </SendTypography>
          </NuxtLink>
        </li>
      </ul>
    </div>

    <ul class="mt-2 flex flex-col gap-2 space-y-2">
      <li>
        <NuxtLink
          active-class="active"
          class="block max-h-max w-fit text-white"
          to="/settings/edit-profile"
        >
          <div class="flex items-center">
            <SendIcon name="Preferences" />
            <span class="ml-2">
              {{ $t("settings") }}
            </span>
          </div>
        </NuxtLink>
      </li>
      <li>
        <div class="w-fit text-white" @click="userLogout">
          <div class="flex cursor-pointer items-center gap-2">
            <SendIcon name="Login" />
            <span v-if="logout.loading">Logging out...</span>
            <span v-else>
              {{ $t("logout") }}
            </span>
          </div>
        </div>
      </li>
      <li>
        <LanguageDropdown class="mt-2" />
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import FwbAvatar from "~/components/Flowbite/FwbAvatar/FwbAvatar.vue";
import LanguageDropdown from "~/components/partials/LanguageDropdown.vue";
import SendBaseButton from "~/components/partials/SendBaseButton.vue";
import SendIcon from "~/components/partials/SendIcon.vue";
import SendTypography from "~/components/partials/SendTypography.vue";
import { useAuthStore } from "~/stores/auth";
import { useUserStore } from "~/stores/user";

const { logout } = useAuthStore();
const userLogout = () => {
  if (logout.loading) return;
  logout.mutate();
};

logout.onDone(() => {
  useFlushAndRedirect();
});

const userStore = useUserStore();
const { username, displayName, avatar } = toRefs(userStore);
</script>

<style scoped>
.active button {
  border: 1px solid white;
}
</style>

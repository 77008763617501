import { defineStore } from "pinia";
import type { Challenge, Profile } from "~/gql/graphql";
export const useProfileStore = defineStore("profilePageStore", () => {
  const createDefaults = () =>
    <Partial<Profile>>{
      bio: "",
      id: "",
      displayName: "",
      username: "",
      avatar: "",
      challengeable: false,
      runningChallengesCount: 0,
      completedChallengesCount: 0,
      uploadedVideosCount: 0,
      uploadedVideos: [],
      respectedVideosCount: 0,
      respectedVideos: [],
      runningChallenges: [],
      completedChallenges: [],
      roles: [],
    };

  const profile = reactive<Profile>(createDefaults());

  const update = (newProfile: Partial<Profile>) =>
    Object.assign(profile, newProfile);

  const flush = () => Object.assign(profile, createDefaults());

  const updateUploadedChallenge = (currentChallenge: Partial<Challenge>) => {
    const index = profile.uploadedVideos?.findIndex((challenge) => {
      return challenge?.slug === currentChallenge.slug;
    });

    profile.uploadedVideos = profile.uploadedVideos?.map((challenge, i) => {
      if (i !== index) {
        return challenge;
      }
      return { ...challenge, ...currentChallenge };
    });
  };

  return {
    ...toRefs(profile),

    // Methods
    update,
    flush,
    updateUploadedChallenge,
  };
});

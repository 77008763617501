<template>
  <div
    v-if="props.showMenu"
    class="fixed left-0 top-[65px] z-50 h-[calc(100vh-65px)] w-screen bg-[#202020] px-10 py-4 lg:hidden"
    @close="emit('update:showMenu', false)"
  >
    <LoggedInMenu v-if="loggedIn" />
    <NotLoggedInMenu v-else />
  </div>
</template>
<script setup lang="ts">
import { useAuthStore } from "~/stores/auth";
import LoggedInMenu from "~/components/navigation/LoggedInMenu.vue";
import NotLoggedInMenu from "~/components/navigation/NotLoggedInMenu.vue";

const props = defineProps<{
  showMenu: boolean;
}>();

const userStore = useAuthStore();
const { loggedIn } = toRefs(userStore);

const emit = defineEmits(["update:showMenu"]);
</script>

<template>
  <div :class="wrapperClasses">
    <label v-if="label" :class="labelClasses">{{ label }}</label>

    <div class="relative h-full">
      <component
        :is="type"
        v-model="model"
        :class="computedClasses"
        :disabled="disabled"
        :placeholder="placeholder"
        :rows="rows"
        :type="currentSubType"
        :value="model"
        v-bind="$attrs"
        @input="onInput"
      >
      </component>
      <button
        v-if="subType === 'password'"
        class="absolute right-3 top-1/2 -translate-y-1/2 transform"
        type="button"
        @click="togglePasswordVisibility"
      >
        <i :class="iconClass"></i>
      </button>
    </div>

    <p
      v-if="validationStatus !== 'none' && validationMessage"
      :class="validationClasses"
    >
      {{ validationMessage }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { useVModel } from "@vueuse/core";
import { computed, ref, type Ref } from "vue";
import type { Maybe } from "~/gql/graphql";

type Sizes = "lg" | "md" | "sm" | "xs";
type InputTypes = "input" | "textarea";
type SubTypes =
  | "text"
  | "password"
  | "email"
  | "number"
  | "tel"
  | "url"
  | "date"
  | "time"
  | "datetime-local"
  | "month"
  | "week";
type ValidationStatus = "success" | "error" | "hint" | "none";

defineOptions({
  inheritAttrs: false,
});

interface Option {
  value: string | number;
  label: string;
}

interface Props {
  size?: Sizes;
  type?: InputTypes;
  subType?: SubTypes;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  modelValue?:
    | string
    | number
    | undefined
    | Ref<string | number | undefined>
    | Maybe<string>;
  validationStatus?: ValidationStatus;
  validationMessage?: string;
  options?: Option[];
  rows?: number;
}

const props = withDefaults(defineProps<Props>(), {
  size: "md",
  type: "input",
  subType: "text",
  disabled: false,
  label: undefined,
  placeholder: undefined,
  modelValue: undefined,
  validationStatus: "none",
  validationMessage: undefined,
  options: undefined,
  rows: 2,
});

const model = useVModel(props, "modelValue");

// Ref to track the current subType
const currentSubType = ref(props.subType);

// Toggle password visibility
const togglePasswordVisibility = () => {
  if (props.subType === "password") {
    currentSubType.value =
      currentSubType.value === "password" ? "text" : "password";
  }
};

const toggleVisibility = (visible: boolean) => {
  currentSubType.value = visible ? "text" : "password";
};

// Icon class based on current state
const iconClass = computed(() => {
  return currentSubType.value === "password"
    ? "icon icon-Eye"
    : "icon icon-EyeClosed";
});

const onInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  model.value = target.value;
};

const baseStyle =
  "flex flex-col justify-center items-start gap-2 bg-white border border-solid border-send-almost-black rounded p-2 transition duration-200 w-full";

const sizeClasses = {
  lg: "text-lg px-4 py-2.5 rounded-2xl",
  md: "text-base px-3 py-2 rounded-xl",
  sm: "text-sm px-2 py-1.5 rounded-lg",
  xs: "text-xs px-1.5 py-1 rounded-sm",
};

const typeClasses = {
  input: "",
  textarea: "",
};

const computedClasses = computed(() => {
  let baseInputStyle = baseStyle;

  if (props.validationStatus == "error") {
    baseInputStyle += " !border-red-500";
  }

  if (props.disabled) {
    baseInputStyle += " user-select-none opacity-50 cursor-not-allowed";
  }

  // console.log(baseInputStyle); // Log the final computed classes
  return `${baseInputStyle} ${sizeClasses[props.size]} ${typeClasses[props.type]}`.trim();
});

const wrapperClasses = computed(() => {
  let classes = "relative flex flex-col align-middle justify-center w-full";

  if (props.size === "lg") {
    classes += " space-y-2";
  } else if (props.size === "md") {
    classes += " space-y-2";
  } else {
    classes += " space-y-1";
  }

  return classes;
});

const labelClasses = computed(() => {
  let classes = "text-sm font-medium text-send-almost-black";

  if (props.size === "lg") {
    classes += " text-md";
  } else if (props.size === "sm") {
    classes += " text-xs";
  }

  return classes;
});

const validationClasses = computed(() => {
  const baseClasses = "text-xs font-medium";

  if (props.validationStatus === "success") {
    return `${baseClasses} text-send-success`;
  } else if (props.validationStatus === "error") {
    return `${baseClasses} text-red-500`;
  } else {
    return `${baseClasses} text-send-grey`;
  }
});

defineExpose({
  toggleVisibility,
});
</script>

<style scoped>
/* Add any additional styling here */
</style>

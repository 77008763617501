<template>
  <div>
    <div :class="overlayClasses" />
    <div
      ref="modalRef"
      :class="containerClasses"
      tabindex="0"
      @click.self="clickOutside"
      @keyup.esc="closeWithEsc"
    >
      <div :class="modalWrapperClasses">
        <!-- Modal content -->
        <div
          :class="modalContentClasses"
          style="-ms-overflow-style: none; scrollbar-width: none"
        >
          <div
            v-if="$slots.fullWidth"
            class="flex h-screen flex-col content-center justify-center bg-black md:h-auto"
          >
            <slot name="fullWidth" />
          </div>

          <!-- Drawer handle for bottom position -->
          <div
            v-if="position === 'bottom'"
            class="mx-auto mt-3 h-1.5 w-12 rounded-full bg-gray-300 md:hidden"
          />

          <!-- Modal header -->
          <div :class="headerClasses">
            <slot name="header" />
            <button
              v-if="!persistent"
              :class="closeButtonClasses"
              aria-label="close"
              type="button"
              @click="closeModal"
            >
              <slot name="close-icon">
                <svg
                  class="h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    fill-rule="evenodd"
                  />
                </svg>
              </slot>
            </button>
          </div>

          <!-- Modal body -->
          <div v-if="$slots.body" class="px-8 py-6">
            <slot name="body" />
          </div>

          <!-- Modal footer -->
          <div
            v-if="$slots.footer"
            class="rounded-b border-t border-gray-200 px-8 py-6"
          >
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, type Ref, useSlots } from "vue";

type Sizes = "lg" | "md" | "sm";
type Position = "default" | "bottom";

interface ModalProps {
  notEscapable?: boolean;
  persistent?: boolean;
  size?: Sizes;
  position?: Position;
  fullWidth?: boolean;
  matchParentContainer?: boolean;
}

const props = withDefaults(defineProps<ModalProps>(), {
  notEscapable: false,
  persistent: false,
  size: "md",
  position: "default",
  fullWidth: false,
  matchParentContainer: false,
});

const emit = defineEmits(["close", "click:outside"]);

const modalRef: Ref<HTMLElement | null> = ref(null);
const slots = useSlots();

const modalSizeClasses = {
  sm: "max-w-screen-sm",
  md: "max-w-xl",
  lg: "max-w-4xl",
} as const;

// Computed: Layout Classes
const overlayClasses = computed(() => [
  "fixed inset-0 z-140 bg-black transition-opacity duration-300 ease-out",
  {
    "animate-fade-in": props.position === "bottom",
    "bg-opacity-30": props.position !== "bottom",
  },
]);

const containerClasses = computed(() => [
  "bottom-0 left-0 right-0 z-150 flex h-full w-full overflow-y-auto overflow-x-hidden",
  {
    "items-end md:items-center md:justify-center": props.position === "bottom",
    "items-center justify-center": props.position !== "bottom",
    "fixed md:inset-0 md:h-full": !props.matchParentContainer,
    absolute: props.matchParentContainer,
  },
]);

const modalWrapperClasses = computed(() => [
  "relative flex w-full max-w-2xl justify-center align-middle",
  !props.fullWidth && modalSizeClasses[props.size],
  props.position === "bottom" && !props.matchParentContainer
    ? "p-0 md:p-4"
    : "",
  props.position === "bottom" && props.matchParentContainer
    ? "md:h-full"
    : "md:p-4",
]);

const modalContentClasses = computed(() => [
  "relative w-full overflow-y-scroll bg-white shadow-purple-outline transition-transform duration-300 ease-out",
  props.position === "bottom" &&
    "max-h-[80vh] md:max-h-none translate-y-0 rounded-t-3xl",
  {
    "md:inset-0 md:!h-[100%] md:rounded-none":
      props.matchParentContainer && props.position === "bottom",
    "md:max-h-screen md:rounded-4xl":
      !props.matchParentContainer && props.position === "bottom",
    "max-h-screen min-h-screen md:min-h-0 md:rounded-4xl":
      props.position !== "bottom",
  },
  props.position !== "bottom" && "h-fit",
]);

const headerClasses = computed(() => [
  "flex items-center justify-between space-x-8 rounded-t px-8 py-6 align-middle",
  slots.header ? "md:border-b md:border-gray-200" : "!p-0",
  props.position === "bottom" ? "pt-4 md:pt-6" : "",
]);

const closeButtonClasses = computed(() => [
  "ml-auto mr-0 inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-send-grey-100 hover:bg-gray-200 hover:text-gray-900",
  slots.header && props.position !== "bottom" ? "" : "absolute right-3 top-3",
]);

function closeModal() {
  emit("close");
}

function clickOutside() {
  if (!props.persistent) {
    emit("click:outside");
    closeModal();
  }
}

function closeWithEsc() {
  if (!props.notEscapable && !props.persistent) closeModal();
}

onMounted(() => {
  if (modalRef.value) {
    modalRef.value.focus();
  }
});
</script>

<style scoped>
.overflow-y-scroll::-webkit-scrollbar {
  display: none;
}

.translate-y-0 {
  animation: slideUp 0.3s ease-out;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 0.3s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.3;
  }
}
</style>

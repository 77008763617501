<template>
  <img
    class="h-auto w-5 md:hidden"
    src="/assets/images/logo_sm.svg"
    alt="SendChallenges"
  />
  <img
    class="hidden h-auto w-20 md:block lg:hidden"
    src="/assets/images/logo_md.svg"
    alt="SendChallenges"
  />
  <img
    class="hidden h-auto w-64 lg:block"
    src="/assets/images/logo_lg.svg"
    alt="SendChallenges"
  />
</template>

<script setup lang="ts"></script>

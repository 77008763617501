import { default as challenge_45dashboardf9Ld2RfQgYMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/challenge-dashboard.vue?macro=true";
import { default as detailVzkSYACl3uMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/challenge/[slug]/detail.vue?macro=true";
import { default as step_4518f7u5ew4AAMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/challenge/create/step-1.vue?macro=true";
import { default as step_452UuxlezWM9KMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/challenge/create/step-2.vue?macro=true";
import { default as email_45preferencesdIL58Q004wMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/email-preferences.vue?macro=true";
import { default as indexJ4r0o4eRNFMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/index.vue?macro=true";
import { default as verifyn9L7lbgiLsMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/login/magic-link/verify.vue?macro=true";
import { default as requestXJoUYalWQJMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/login/recover/request.vue?macro=true";
import { default as resetqbe5FG6ZOBMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/login/recover/reset.vue?macro=true";
import { default as recoverGRLMqKt6PVMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/login/recover.vue?macro=true";
import { default as login3CJywj3WCWMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/login.vue?macro=true";
import { default as moderatorJpJeLA5eowMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/moderator.vue?macro=true";
import { default as successCukW5ZCNNLMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/payment/success.vue?macro=true";
import { default as completed_45challengesDqj0wxRYKKMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/profile/[username]/completed-challenges.vue?macro=true";
import { default as respected_45videosULEb93DJESMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/profile/[username]/respected-videos.vue?macro=true";
import { default as running_45challengesWeDaepzqudMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/profile/[username]/running-challenges.vue?macro=true";
import { default as uploaded_45videoslbwBiJCozXMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/profile/[username]/uploaded-videos.vue?macro=true";
import { default as _91username_93FirU4LnKhEMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/profile/[username].vue?macro=true";
import { default as ProfileTabButtontJg80sYB4vMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/profile/ProfileTabButton.vue?macro=true";
import { default as change_45password7d5oMxGWbYMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/settings/change-password.vue?macro=true";
import { default as edit_45accountwJ9gameNKGMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/settings/edit-account.vue?macro=true";
import { default as edit_45email_45preferencesg1WubZdM48Meta } from "/builds/Partout/sendchallenges/src/frontend/pages/settings/edit-email-preferences.vue?macro=true";
import { default as edit_45paymentsDy7CbSrCleMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/settings/edit-payments.vue?macro=true";
import { default as edit_45profilej5xB8yMrwcMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/settings/edit-profile.vue?macro=true";
import { default as guidelines8VI55K2EouMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/settings/guidelines.vue?macro=true";
import { default as settingsRb4Rjo01RyMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/settings.vue?macro=true";
import { default as termsKUCZJjzS9LMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/terms.vue?macro=true";
import { default as PayoutModalRhOvEELHsgMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/wallet/PayoutModal.vue?macro=true";
import { default as walletJIhPwnhvnQMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/wallet.vue?macro=true";
import { default as component_45stubrumMsMXAl1Meta } from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.31_eslint@8.57.1_sass@1.75.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubrumMsMXAl1 } from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.31_eslint@8.57.1_sass@1.75.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "challenge-dashboard",
    path: "/challenge-dashboard",
    meta: challenge_45dashboardf9Ld2RfQgYMeta || {},
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/challenge-dashboard.vue")
  },
  {
    name: "challenge-slug-detail",
    path: "/challenge/:slug()/detail",
    meta: detailVzkSYACl3uMeta || {},
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/challenge/[slug]/detail.vue")
  },
  {
    name: "challenge-create-step-1",
    path: "/challenge/create/step-1",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/challenge/create/step-1.vue")
  },
  {
    name: "challenge-create-step-2",
    path: "/challenge/create/step-2",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/challenge/create/step-2.vue")
  },
  {
    name: "email-preferences",
    path: "/email-preferences",
    meta: email_45preferencesdIL58Q004wMeta || {},
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/email-preferences.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login3CJywj3WCWMeta || {},
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/login.vue"),
    children: [
  {
    name: "login-magic-link-verify",
    path: "magic-link/verify",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/login/magic-link/verify.vue")
  },
  {
    name: "login-recover",
    path: "recover",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/login/recover.vue"),
    children: [
  {
    name: "login-recover-request",
    path: "request",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/login/recover/request.vue")
  },
  {
    name: "login-recover-reset",
    path: "reset",
    meta: resetqbe5FG6ZOBMeta || {},
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/login/recover/reset.vue")
  }
]
  }
]
  },
  {
    name: "moderator",
    path: "/moderator",
    meta: moderatorJpJeLA5eowMeta || {},
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/moderator.vue")
  },
  {
    name: "payment-success",
    path: "/payment/success",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/payment/success.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93FirU4LnKhEMeta || {},
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/profile/[username].vue"),
    children: [
  {
    name: "profile-username-completed-challenges",
    path: "completed-challenges",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/profile/[username]/completed-challenges.vue")
  },
  {
    name: "profile-username-respected-videos",
    path: "respected-videos",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/profile/[username]/respected-videos.vue")
  },
  {
    name: "profile-username-running-challenges",
    path: "running-challenges",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/profile/[username]/running-challenges.vue")
  },
  {
    name: "profile-username-uploaded-videos",
    path: "uploaded-videos",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/profile/[username]/uploaded-videos.vue")
  }
]
  },
  {
    name: "profile-ProfileTabButton",
    path: "/profile/ProfileTabButton",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/profile/ProfileTabButton.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsRb4Rjo01RyMeta || {},
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/settings.vue"),
    children: [
  {
    name: "settings-change-password",
    path: "change-password",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/settings/change-password.vue")
  },
  {
    name: "settings-edit-account",
    path: "edit-account",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/settings/edit-account.vue")
  },
  {
    name: "settings-edit-email-preferences",
    path: "edit-email-preferences",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/settings/edit-email-preferences.vue")
  },
  {
    name: "settings-edit-payments",
    path: "edit-payments",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/settings/edit-payments.vue")
  },
  {
    name: "settings-edit-profile",
    path: "edit-profile",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/settings/edit-profile.vue")
  },
  {
    name: "settings-guidelines",
    path: "guidelines",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/settings/guidelines.vue")
  }
]
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/terms.vue")
  },
  {
    name: "wallet",
    path: "/wallet",
    meta: walletJIhPwnhvnQMeta || {},
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/wallet.vue"),
    children: [
  {
    name: "wallet-PayoutModal",
    path: "PayoutModal",
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/wallet/PayoutModal.vue")
  }
]
  },
  {
    name: component_45stubrumMsMXAl1Meta?.name,
    path: "/challenge/create",
    component: component_45stubrumMsMXAl1
  }
]
<template>
  <component
    :is="tag"
    :disabled="disabled"
    :class="[sharedButtonClasses, buttonVariant, buttonSize, buttonWeight]"
  >
    <div
      class="flex w-full items-center justify-center gap-2"
      :class="iconPosition === 'start' && 'flex-row-reverse'"
    >
      <span v-if="props.label">{{ props.label }}</span>
      <SendIcon v-if="iconName?.length" :name="iconName" size="2xl" />
    </div>
  </component>
</template>

<script setup lang="ts">
import type { IconsId } from "~/assets/css/icons/icons";
import SendIcon from "~/components/partials/SendIcon.vue";

//eslint-disable-next-line
type ValidTag = keyof HTMLElementTagNameMap;

type BaseButton = {
  variant?: "primary" | "secondary" | "tertiary" | "action" | "action-gradient";
  size?: "xs" | "sm" | "md" | "lg";
  label?: string | null;
  weight?: "light" | "normal" | "medium" | "semi-bold" | "bold";
  iconName?: IconsId;
  iconPosition?: "start" | "end";
  disabled?: boolean;
  as?: ValidTag;
};

const props = withDefaults(defineProps<BaseButton>(), {
  label: null,
  as: "button",
  variant: "primary",
  size: "lg",
  weight: "medium",
  iconName: undefined,
  iconPosition: "end",
  disabled: false,
});

const tag = computed(() => props.as || "button");

const sharedButtonClasses =
  "font-send-default cursor-pointer inline-flex justify-center items-center transition-colors duration-200 ease-in-out disabled:cursor-not-allowed whitespace-nowrap";

const buttonVariant = {
  primary:
    "bg-send-almost-black border-2 border-send-almost-black text-white hover:bg-black disabled:border-send-grey-80 disabled:bg-send-grey-80",
  secondary:
    "border-send-almost-black border-2 text-send-almost-black border-send-almost-black disabled:border-send-grey-80 disabled:text-send-grey-80 hover:bg-send-grey-nav",
  tertiary:
    "text-send-purple-1 border-2 border-transparent disabled:text-send-grey-80 hover:text-send-purple-2",
  action:
    "text-send-almost-black border-2 border-send-almost-black bg-send-yellow disabled:bg-send-grey-60 disabled:border-send-grey-100 disabled:text-send-grey-100 hover:border-black hover:text-black hover:-rotate-1 hover:scale-105 transition-transform duration-200 ease-in-out",
  "action-gradient":
    "text-send-almost-black text-white bg-action-gradient disabled:bg-send-grey-60 disabled:border-send-grey-100 disabled:text-send-grey-100 transition-transform duration-200 ease-in-out hover:scale-105",
}[props.variant || "primary"];

const buttonSize = {
  xs: "text-sm rounded-xl px-3 py-1",
  sm: "text-md rounded-xl px-3 py-2",
  md: "text-lg rounded-xl px-4 py-3",
  lg: "text-2xl rounded-2xl px-6 py-4",
}[props.size || "lg"];

const buttonWeight = {
  light: "font-light",
  normal: "font-normal",
  medium: "font-medium",
  "semi-bold": "font-semibold",
  bold: "font-bold",
}[props.weight || "medium"];
</script>

import revive_payload_client_6phk5LNplE from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.31_eslint@8.57.1_sass@1.75.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_NMdcB0ZZt2 from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.31_eslint@8.57.1_sass@1.75.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_4bx9mxJPet from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.31_eslint@8.57.1_sass@1.75.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kRbGA27Zmp from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.31_eslint@8.57.1_sass@1.75.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_He6vKtN2Fw from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.31_eslint@8.57.1_sass@1.75.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_zHjt1ktgfq from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.31_eslint@8.57.1_sass@1.75.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_X8pKtygX9C from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.31_eslint@8.57.1_sass@1.75.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_hYWUrOLuQJ from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.6.2_vue@3.5.10/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/Partout/sendchallenges/src/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_3DdAfSqGI2 from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.31_eslint@8.57.1_sass@1.75.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_9RNjyLX3El from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_pinia@2.1.7/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import apollo_1ILFkzpYSr from "/builds/Partout/sendchallenges/src/frontend/plugins/apollo.ts";
import i18n_VfGcjrvSkj from "/builds/Partout/sendchallenges/src/frontend/plugins/i18n.ts";
import sentry_3AyO8nEfhE from "/builds/Partout/sendchallenges/src/frontend/plugins/sentry.ts";
import toast_ysMjUcU7eJ from "/builds/Partout/sendchallenges/src/frontend/plugins/toast.ts";
export default [
  revive_payload_client_6phk5LNplE,
  unhead_NMdcB0ZZt2,
  router_4bx9mxJPet,
  payload_client_kRbGA27Zmp,
  navigation_repaint_client_He6vKtN2Fw,
  check_outdated_build_client_zHjt1ktgfq,
  chunk_reload_client_X8pKtygX9C,
  plugin_vue3_hYWUrOLuQJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_3DdAfSqGI2,
  plugin_9RNjyLX3El,
  apollo_1ILFkzpYSr,
  i18n_VfGcjrvSkj,
  sentry_3AyO8nEfhE,
  toast_ysMjUcU7eJ
]
import { graphql } from "~/gql";

export const verifyUser = graphql(`
  mutation verifyUser($input: VerifyUserInput!) {
    verifyUser(input: $input) {
      accessToken
      status {
        success
        identifier
        message
      }
    }
  }
`);

export const getCurrentUserProfileQuery = graphql(`
  query getCurrentUserProfile {
    getCurrentUserProfile {
      profile {
        username
        displayName
        bio
        avatar
        runningChallengesCount
        completedChallengesCount
        uploadedVideosCount
        respectedVideosCount
        roles
      }
    }
  }
`);

export const getActivityLogByUserQuery = graphql(`
  query getActivityLogByUser {
    getActivityLogByUser {
      status {
        success
        identifier
        message
      }
      activityLogs {
        type
        challenge {
          slug
          challengeName
          challengeReward
        }
        user {
          username
          displayName
          avatar
        }
        timestamp
      }
    }
  }
`);

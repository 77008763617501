<template>
  <ul class="text-grey pb-5 leading-[3rem]">
    <li>
      <NuxtLink to="/challenge/create">
        <SendBaseButton
          :label="$t('sidebar_start_a_challenge')"
          variant="action-gradient"
          size="xs"
          icon-name="lightning_bolt"
          icon-position="start"
        ></SendBaseButton>
      </NuxtLink>
    </li>
  </ul>
  <div class="rounded-xl bg-send-almost-black p-4">
    <p class="mb-3 text-sm leading-loose text-white">
      {{ $t("sidebar_login_text") }}
    </p>
    <NuxtLink @click="openMagicLinkLoginModal">
      <SendBaseButton
        :label="$t('sidebar_login_button')"
        variant="primary"
        size="xs"
        icon-name="Profile"
        icon-position="start"
        class="!gap-1 border-none bg-white !text-black hover:!bg-black hover:!text-white"
      />
    </NuxtLink>
  </div>

  <LanguageDropdown class="mt-4" />
</template>

<script setup lang="ts">
import LanguageDropdown from "~/components/partials/LanguageDropdown.vue";
import SendBaseButton from "~/components/partials/SendBaseButton.vue";

const router = useRouter();
const route = useRoute();

const openMagicLinkLoginModal = () => {
  try {
    router.push({ query: { ...route.query, modal: "login-magic-link" } });
  } catch (e) {
    router.push({
      path: "/challenge/create/step-1",
      query: { ...route.query, modal: "login-magic-link" },
    });
  }
};
</script>

<template>
  <div class="flex flex-col gap-6">
    <h3 class="font-bold">{{ $t("what_is_your_email_address") }}</h3>
    <p class="text-sm text-gray-500">
      {{ $t("we_will_send_you_an_email_to_verify_identity") }}
    </p>
    <div class="flex w-full flex-col justify-center gap-2 lg:flex-row">
      <SendInput
        v-model="form.email"
        :placeholder="$t('placeholder_email')"
        type="input"
        sub-type="email"
        size="lg"
        @keyup.enter.stop="!v.$invalid && loginWithMagicLink()"
      ></SendInput>
      <SendBaseButton
        :label="$t('send_link')"
        :disabled="v.$invalid || requestMagicLink.loading"
        variant="primary"
        size="md"
        @click="loginWithMagicLink"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { useAuthStore } from "~/stores/auth";
import SendBaseButton from "~/components/partials/SendBaseButton.vue";
import { useToast } from "vue-toastification";
import SendInput from "~/components/partials/SendInput.vue";

const emit = defineEmits(["request-magic-link"]);
const { requestMagicLink } = useAuthStore();

const form = reactive({
  email: null as null | undefined | string,
  sentToEmail: null as null | undefined | string,
});

const v = useVuelidate(
  {
    email: {
      required,
      email,
    },
  },
  form
);

const loginWithMagicLink = async () => {
  const currentUrl = window.location.href;
  let url = currentUrl.split("?")[0];
  url = url.replace(window.location.origin, "");

  requestMagicLink.mutate({ email: form.email!, originUrl: url });
};

requestMagicLink.onDone((result) => {
  emit("request-magic-link", { email: result.data?.requestLoginLink.email });
});

requestMagicLink.onError((error) => {
  useToast().error(error.message);
});
</script>

import { defineStore } from "pinia";
import type { Profile } from "~/gql/graphql";

export type Locale = "nl" | "en";

interface UserStoreState extends Partial<Profile> {
  showPrivateChallenges: boolean;
  locale: Locale;
  hideAvailableAmount: boolean;
}

export const useUserStore = defineStore(
  "userStore",
  () => {
    const createDefaults = () =>
      <UserStoreState>{
        displayName: "",
        username: "",
        avatar: "",
        bio: "",
        locale: "en",
        roles: [],
        showPrivateChallenges: false,
        hideAvailableAmount: false,
      };

    const state = reactive(createDefaults());

    const getDateLocale = computed(() => {
      return state.locale === "nl" ? "nl-NL" : "en-GB";
    });

    const update = (newState: Partial<UserStoreState>) =>
      Object.assign(state, newState);

    const flush = () => Object.assign(state, createDefaults());

    return {
      ...toRefs(state),

      // Methods
      getDateLocale,
      update,
      flush,
    };
  },
  {
    persist: true,
  }
);

<!-- components/modals/LoginMagicLinkModal.vue -->
<template>
  <SendModal v-if="showModal" size="lg" position="bottom" @close="closeModal">
    <template #header>
      <SendTypography
        as="h2"
        class="w-full py-8 md:text-center"
        variant="heading"
        size="md"
      >
        {{ $t("log_in_with_magic_link") }}
      </SendTypography>
    </template>
    <template #body>
      <div v-if="!verify" class="flex flex-col gap-4">
        <LoginMagicLinkForm @request-magic-link="verifyRequested" />
      </div>
      <div v-else-if="verify" class="flex flex-col gap-4">
        <LoginMagicLinkVerifyForm @logged-in="loggedIn" />
      </div>
      <div class="relative inline-flex w-full items-center justify-center">
        <hr class="my-4 h-[1px] w-11/12 border-0 bg-gray-300 lg:my-8" />
        <div class="text-grey absolute left-1/2 -translate-x-1/2 bg-white px-4">
          {{ $t("or") }}
        </div>
      </div>
      <div class="flex items-center justify-center pb-4">
        <NuxtLink
          class="cursor-pointer bg-action-gradient bg-clip-text font-medium [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]"
          @click="goToLoginUsername"
        >
          {{ $t("log_in_with_username_password") }}
        </NuxtLink>
      </div>
    </template>
  </SendModal>
</template>

<script setup lang="ts">
import type { Ref } from "vue";
import LoginMagicLinkForm from "~/components/auth/LoginMagicLinkForm.vue";
import LoginMagicLinkVerifyForm from "~/components/auth/LoginMagicLinkVerifyForm.vue";
import SendModal from "~/components/partials/SendModal.vue";
import SendTypography from "~/components/partials/SendTypography.vue";

const { currentUserProfileQuery } = useUser();

const router = useRouter();
const route = useRoute();

defineProps<{
  showModal: boolean;
}>();

const requestedEmail: Ref<string | undefined> = ref(undefined);
const verify: Ref<boolean> = ref(false);

const emit = defineEmits(["close"]);

const verifyRequested = (payload: { email: string }) => {
  requestedEmail.value = payload.email;
  verify.value = true;
  router.push({ query: { ...route.query, email: payload.email } });
};

const loggedIn = async () => {
  await currentUserProfileQuery.load();
  await router.push({ query: { ...route.query, email: undefined } });
  closeModal();
};

const goToLoginUsername = () => {
  router.push({ query: { ...route.query, modal: "login-username-password" } });
};

const closeModal = () => {
  emit("close");
};
</script>

<template>
  <i class="icon" :class="[`icon-${name}`, `${computedSize}`]" />
</template>

<script setup lang="ts">
import type { IconsId } from "~/assets/css/icons/icons";

export type IconSize = "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl";

const props = defineProps<{
  name: IconsId;
  size?: IconSize;
}>();

const computedSize = computed(() => {
  let textSize = "text-xl";

  switch (props.size) {
    case "sm":
      textSize = "text-sm";
      break;
    case "md":
      textSize = "text-md";
      break;
    case "lg":
      textSize = "text-lg";
      break;
    case "xl":
      textSize = "text-xl";
      break;
    case "2xl":
      textSize = "text-2xl";
      break;
    case "3xl":
      textSize = "text-3xl";
      break;
    case "4xl":
      textSize = "text-4xl";
      break;
  }

  return textSize;
});
</script>

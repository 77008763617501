import { defineStore } from "pinia";
import type { Challenge } from "~/gql/graphql";

type State = {
  challenge: null | undefined | Challenge;
  openChallenges: null | undefined | Challenge[];
  openChallengeCount: null | undefined | number;
  runningChallenges: null | undefined | Challenge[];
  runningChallengeCount: null | undefined | number;
  requestedChallenges: null | undefined | Challenge[];
  requestedChallengeCount: null | undefined | number;
};

// https://pinia.vuejs.org/core-concepts/#Setup-Stores
export const useChallengeDetailsStore = defineStore("challengeDetails", () => {
  const state = reactive<State>({
    challenge: null,
    openChallenges: null,
    openChallengeCount: 0,
    runningChallenges: null,
    runningChallengeCount: 0,
    requestedChallenges: null,
    requestedChallengeCount: 0,
  });

  const reset = () => {
    state.challenge = null;
  };

  return {
    reset,
    ...toRefs(state),
  };
});

<template>
  <SendDropdown
    v-model="locale"
    size="sm"
    variant="ghost"
    :options="options"
    class="!w-fit"
    placement="top"
    @change="changeLanguage"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import SendDropdown from "~/components/partials/SendDropdown.vue";

const { locale } = toRefs(useUserStore());
const i18n = useI18n();
const options = [
  { value: "nl", label: "NL" },
  { value: "en", label: "EN" },
];

const changeLanguage = (lang: "nl" | "en") => {
  locale.value = lang;
  i18n.locale.value = lang;
};
</script>

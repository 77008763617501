<template>
  <div class="flex flex-col gap-4">
    <div class="font-bold">
      {{ $t("log_in_thank_you_email", { email }) }}
    </div>
    <p>{{ $t("log_in_check_your_mailbox") }}</p>

    <div class="flex flex-col gap-2 align-middle lg:flex-row">
      <SendInput
        v-model="code"
        placeholder="Code"
        size="lg"
        @keyup.enter.stop="!v.$invalid && login()"
      />
      <SendBaseButton
        :label="$t('log_in')"
        :disabled="v.$invalid || loginUsingMagicLinkCode.loading"
        :loading="loginUsingMagicLinkCode.loading"
        variant="primary"
        size="md"
        @click="login"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import SendBaseButton from "~/components/partials/SendBaseButton.vue";
import SendInput from "~/components/partials/SendInput.vue";
import { useAuthStore } from "~/stores/auth";

const route = useRoute();
const router = useRouter();
const email = computed(() =>
  typeof route.query.email === "string" ? route.query.email : "unknown"
);

const code = ref("");

const emit = defineEmits(["loggedIn"]);

const { loginUsingMagicLinkCode } = useAuthStore();
const { verifyingUser } = toRefs(useAuthStore());

const v = useVuelidate(
  {
    code: {
      required,
    },
  },
  { code }
);

const login = () => {
  if (v.$invalid || loginUsingMagicLinkCode.loading) return;
  loginUsingMagicLinkCode.mutate({ email: email.value, code: code.value });
};

loginUsingMagicLinkCode.onDone(async (result) => {
  verifyingUser.value.email = email.value;
  verifyingUser.value.code = code.value;

  if (result.data?.verifyLoginCode?.originUrl) {
    await router.replace(result.data.verifyLoginCode.originUrl);
  }

  emit("loggedIn");
});
</script>

<style scoped></style>

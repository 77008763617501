<template>
  <div ref="dropdownWrapper" :class="wrapperClasses">
    <label v-if="label" :class="labelClasses">{{ label }}</label>

    <div class="relative">
      <div :class="computedClasses" @click="toggleDropdown">
        <span v-if="selectedLabel === placeholder" class="text-send-grey">
          {{ selectedLabel }}
        </span>
        <span v-else>{{ selectedLabel }}</span>
        <svg
          class="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 transform"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            v-if="variant === 'default'"
            d="M10.9948 15.7878L5.20927 9.92109C4.93024 9.63814 4.93024 9.17941 5.20927 8.89649L5.88406 8.21223C6.16262 7.92977 6.61407 7.92923 6.89328 8.21103L11.5 12.8605L16.1067 8.211C16.3859 7.9292 16.8374 7.92974 17.1159 8.2122L17.7907 8.89646C18.0698 9.17941 18.0698 9.63814 17.7907 9.92106L12.0052 15.7878C11.7262 16.0707 11.2738 16.0707 10.9948 15.7878Z"
            fill="#333"
          />
          <path
            v-else-if="variant === 'ghost'"
            d="M10.9948 15.7878L5.20927 9.92109C4.93024 9.63814 4.93024 9.17941 5.20927 8.89649L5.88406 8.21223C6.16262 7.92977 6.61407 7.92923 6.89328 8.21103L11.5 12.8605L16.1067 8.211C16.3859 7.9292 16.8374 7.92974 17.1159 8.2122L17.7907 8.89646C18.0698 9.17941 18.0698 9.63814 17.7907 9.92106L12.0052 15.7878C11.7262 16.0707 11.2738 16.0707 10.9948 15.7878Z"
            fill="#fff"
          />
        </svg>
      </div>

      <ul v-if="isOpen" :class="optionsWrapperClasses">
        <li
          v-for="option in options"
          :key="option.value"
          :class="optionDetailClasses"
          @click="selectOption(option)"
        >
          {{ option.label }}
        </li>
      </ul>
    </div>

    <p
      v-if="validationStatus !== 'none' && validationMessage"
      :class="validationClasses"
    >
      {{ validationMessage }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useVModel } from "@vueuse/core";

type Sizes = "lg" | "md" | "sm";
type ValidationStatus = "success" | "error" | "hint" | "none";
type Placement = "top" | "bottom";
type Variant = "default" | "ghost";

interface Option {
  value: string | number;
  label: string;
}

interface Props {
  size?: Sizes;
  variant?: Variant;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  modelValue?: string | number;
  validationStatus?: ValidationStatus;
  validationMessage?: string;
  options?: Option[];
  placement?: Placement;
}

const props = withDefaults(defineProps<Props>(), {
  size: "md",
  variant: "default",
  disabled: false,
  label: undefined,
  placeholder: undefined,
  modelValue: undefined,
  validationStatus: "none",
  validationMessage: undefined,
  options: undefined,
  placement: "bottom",
});

const model = useVModel(props, "modelValue");
const emit = defineEmits(["update:modelValue", "change"]);

const isOpen = ref(false);
const dropdownWrapper = ref<HTMLElement | null>(null);

const toggleDropdown = () => {
  if (!props.disabled) {
    isOpen.value = !isOpen.value;
  }
};

const selectOption = (option: Option) => {
  model.value = option.value;
  isOpen.value = false;
  emit("change", option.value);
};

const selectedLabel = computed(() => {
  let selected = undefined;
  if (props.options != undefined) {
    selected = props.options.find((option) => option.value === model.value);
  }

  return selected ? selected.label : props.placeholder;
});

const baseDefaultStyle =
  "bg-white border border-black transition duration-200 w-full cursor-pointer flex justify-between items-center";
const baseGhostStyle =
  "bg-transparent border transition duration-200 border-white w-full text-white";

const sizeClasses = {
  lg: "text-lg px-4 py-2.5 rounded-2xl pr-12",
  md: "text-base px-3 py-2 rounded-xl pr-12",
  sm: "text-sm px-2 py-1.5 rounded-lg pr-12",
};

const computedClasses = computed(() => {
  let baseInputStyle = baseDefaultStyle;

  if (props.variant === "ghost") {
    baseInputStyle = baseGhostStyle;
  }

  if (props.validationStatus === "error") {
    baseInputStyle += " border-red-500";
  }

  if (props.disabled) {
    baseInputStyle += " opacity-50 cursor-not-allowed";
  }

  return `${baseInputStyle} ${sizeClasses[props.size]}`.trim();
});

const wrapperClasses = computed(() => {
  let classes = "relative flex flex-col";

  if (props.size === "lg") {
    classes += " space-y-2";
  } else if (props.size === "md") {
    classes += " space-y-2";
  } else {
    classes += " space-y-1";
  }

  return classes;
});

const optionsWrapperClasses = computed(() => {
  const classes = "absolute w-full bg-white border border-black z-10";

  if (props.placement === "top") {
    return `${classes} top-0 -translate-y-full`.trim();
  } else {
    return `${classes}`.trim();
  }
});

const optionDetailClasses = computed(() => {
  const classes = "hover:bg-gray-100 cursor-pointer";

  return `${classes} ${sizeClasses[props.size]}`.trim();
});

const labelClasses = computed(() => {
  let classes = "text-sm font-medium text-send-almost-black";

  if (props.size === "lg") {
    classes += " text-md";
  } else if (props.size === "sm") {
    classes += " text-xs";
  }

  return classes;
});

const validationClasses = computed(() => {
  const baseClasses = "text-xs font-medium";

  if (props.validationStatus === "success") {
    return `${baseClasses} text-send-success`;
  } else if (props.validationStatus === "error") {
    return `${baseClasses} text-red-500`;
  } else {
    return `${baseClasses} text-send-grey`;
  }
});

const handleClickOutside = (event: MouseEvent) => {
  if (
    dropdownWrapper.value &&
    !dropdownWrapper.value.contains(event.target as Node)
  ) {
    isOpen.value = false;
  }
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>

<style scoped></style>

export default () => {
  const authStore = useAuthStore();
  const challengeDetailsStore = useChallengeDetailsStore();
  const createChallengeStore = useCreateChallengeStore();
  const moderatorStore = useModeratorStore();
  const paymentPageStore = usePaymentPageStore();
  const profilePageStore = useProfileStore();
  const settingsPageStore = useSettingsStore();
  const userStore = useUserStore();
  const walletStore = useWalletStore();
  const emailPreferencesStore = useEmailPreferencesStore();
  const router = useRouter();

  authStore.reset();
  challengeDetailsStore.reset();
  createChallengeStore.flush();
  moderatorStore.flush();
  paymentPageStore.flush();
  profilePageStore.flush();
  settingsPageStore.flush();
  userStore.flush();
  walletStore.flush();
  emailPreferencesStore.flush();

  router.push("/challenge/create");
};
